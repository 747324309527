<template>
  <div class="consent-page-wrapper">
    <div class="container">
      <h1>СОГЛАСИЕ НА РЕКЛАМНУЮ РАССЫЛКУ</h1>
      <p>
        Заполняя формы на сайте, пользователь дает обществу с ограниченной
        ответственностью “Лаборатория эмоционального интеллекта” (далее --
        Администратор) согласие на получение рекламной рассылки в виде e-mail
        или sms-сообщений и push-уведомлений путем проставления “галочки” в
        соответствующем окошке.
      </p>
      <p>
        Пользователь дает Администратору согласие на обработку его персональных
        данных, в том числе: ФИО, адреса электронной почты, номера мобильного
        телефона и других предоставленных им данных, в целях направления
        рекламной рассылки. Обработка персональных данных осуществляется в
        соответствии с Политикой Администратора.
      </p>
      <p>
        Пользователь уведомлен, что вправе в любое время отозвать согласие на
        получение рекламной рассылки, уведомив об этом Администратора по
        электронной почте:
        <a type="mail" href="connect@eilab.ru."></a>connect@eilab.ru.
      </p>
      <p>
        Согласие на получение рекламной рассылки действует с момента его
        предоставления и до момента получения Администратором от Пользователя
        отказа от получения рекламных рассылок.
      </p>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
export default {
  name: "advert",
  setup() {
    useHead({
      title: "Согласие на рекламную рассылку",
    });
  },
};
</script>

<style lang="scss" scoped>
.consent-page-wrapper {
  width: 100%;
  padding: 121px 0 50px;
  font-size: 17px;
  line-height: 27px;
  color: #414352;

  .container {
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: 1170px;
  }

  .list {
    margin-left: 10px;
    margin-top: 10px;
  }

  .head-text {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 28px;
    line-height: 1.2;
    margin: 30px 0 15px;
  }

  p {
    margin: 0 0 15px;
  }

  ul,
  ol {
    margin: 0 0 15px;
    padding: 0 0 0 40px;
  }

  @media (max-width: 1189px) {
    padding: 140px 20px 30px;

    .container {
      padding: 0 15px;
    }

    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
  }

  @media (max-width: 764px) {
    padding: 80px 0 30px;
  }
}
</style>